

































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { CropStore, CropVarietyStore, UserStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import SCMAddVariety from "@/views/Admin/SCMAddVariety.vue";
import { Crop, CropVariable, CropVariety } from "@/store/models/cropVariety";
import { Validator, Rule } from "vee-validate";

Validator.extend("fileUploaded", {
  getMessage: (field: any) => {
    return "At least one image is required";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      if (args[0]) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

@Component({
  components: {},
  metaInfo: {
    title: "Crop List",
    meta: [
      { name: "keyword", content: "Sqcc crop list" },
      {
        name: "description",
        content: "List of crop of SQCC",
        vmid: "cropList"
      }
    ]
  }
})
export default class SCM extends Vue {
  username: string = "";

  settingLoading: boolean = false;
  savingSetting: boolean = false;
  saving: boolean = false;

  dialogFormVisible: boolean = false;
  dialogDetailVisible: boolean = false;
  dialogVisible: boolean = false;
  dialogCropVariableSettingVisible: boolean = false;
  selectedIndex = -1;
  language: string = "";
  pageNum: number = 1;
  cropId: number = 0;
  crop = {} as CropVariety;
  cropvariable = {} as Crop;
  fileList: File[] = [];
  file: File = {} as File;
  loading: boolean = false;
  dialogLoading: boolean = false;

  get setting() {
    let setting = CropVarietyStore.CropVarietySetting;
    // if (setting && setting.querytype == 'range') {
    //   if(setting.listofsearchvalues[0]) {
    //   setting.minValue = Number(setting.listofsearchvalues[0])
    //   }
    //   if(setting.listofsearchvalues[1]) {
    //   setting.maxValue = Number(setting.listofsearchvalues[1])
    // }
    // }
    return setting;
  }

  get settingTitle() {
    let title = "";
    if (this.setting && this.setting.name) {
      title = this.setting.name + " ";
    }
    title += "Settings for " + this.crop.croptypename;
    return title;
  }

  get categories() {
    return CropVarietyStore.cropCategory;
  }

  handleFileUpload(event: any) {
    this.fileList = [event];
    this.file = event.raw;
  }

  handleRemove() {
    this.fileList = [];
    this.file = {} as File;
  }

  addCrop() {
    this.handleRemove();
    this.$validator.reset();
    let image = this.$validator.fields.find({ name: "Image" });
    if (image) {
      image.update({ name: "Image", rules: "required|image" });
    }
    this.crop = {} as CropVariety;
    this.dialogFormVisible = true;
    this.selectedIndex = -1;
  }

  private async setCrop() {
    // let image = this.$validator.fields.find({ name: "Image" });
    // if (image) {
    //   image.update({ name: "Image", rules: "required|image" });
    // }
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.crop.carousel == undefined) {
        this.crop.carousel = false;
        this.crop.carousel_order = null;
      }
      if (this.crop.carousel == false) {
        this.crop.carousel_order = null;
      }
      let saveData = new FormData();
      saveData.append("file", this.file);
      saveData.append("name", this.crop.croptypename);
      if (this.crop.croptypenamenep) {
        saveData.append("nepname", this.crop.croptypenamenep);
      }
      saveData.append("carousel", String(this.crop.carousel));
      if (this.crop.carousel_order && this.crop.carousel_order != null) {
        saveData.append("carousel_order", String(this.crop.carousel_order));
      }
      saveData.append("categoryid", String(this.crop.CategoryId));
      this.saving = true;
      let error: boolean = false;
      await CropVarietyStore.saveCrop(saveData).catch(reason => {
        error = true;
      });
      this.saving = false;
      if (error) {
        this.$notify.error("Saving Failed");
      } else {
        CropStore.getAllCropName();
        this.fileList = [] as File[];
        this.file = {} as File;
        this.$validator.reset();
        this.$notify.success("Saved Successfully");
        this.handleRemove();
        this.dialogFormVisible = false;
        if (CropVarietyStore.ResponseCrop && CropVarietyStore.ResponseCrop.croptypeid) {
          this.editForm(CropVarietyStore.ResponseCrop.croptypeid);
        }
      }
    } else {
      this.$notify.error("Invalid form data");
    }
  }

  async editForm(id: number) {
    this.handleRemove();
    this.$validator.reset();
    let image = this.$validator.fields.find({ name: "Image" });
    if (image) {
      image.update({ name: "Image", rules: "image" });
    }
    // this.selectedIndex = i;
    this.dialogVisible = true;
    this.dialogLoading = true;
    await CropVarietyStore.getCropVarietyForAdminById(id);
    this.crop = CropVarietyStore.CropVariety;
    this.dialogLoading = false;
  }

  async cropvariabledetail(id: number) {
    // this.selectedIndex = id;
    this.dialogDetailVisible = true;
    this.dialogLoading = true;
    await CropVarietyStore.getCropVarietyForAdminById(id);
    this.crop = CropVarietyStore.CropVariety;
    this.dialogLoading = false;
  }

  // private get crops() {
  //   return CropVarietyStore.Crop;
  // }

  private get cropvarietiesmetavar() {
    return CropVarietyStore.CropVarietiesMetaVar;
  }

  private get cropvarietyforadmin() {
    return CropStore.CropNameList;
  }

  async setCropVariable() {
    // let image = this.$validator.fields.find({ name: "Image" });
    // if (image) {
    //   image.update({ name: "Image", rules: "image" });
    // }
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let rowIndex = this.selectedIndex;
      // this.crop = Object.assign({}, this.cropvarietyforadmin[rowIndex]);
      if (this.crop.carousel == false) {
        this.crop.carousel_order = null;
      }
      let temp = {
        categoryid: this.crop.CategoryId,
        crop: this.crop.croptypename,
        nepcrop: this.crop.croptypenamenep,
        croptypeid: this.crop.croptypeid,
        variables: this.crop.meta,
        carousel: this.crop.carousel,
        carousel_order: this.crop.carousel_order
      };

      let saveData = new FormData();
      saveData.append("file", this.file);
      saveData.append("name", this.crop.croptypename);
      saveData.append("croptypeid", String(this.crop.croptypeid));
      if (this.fileList.length != 0) {
        CropVarietyStore.saveCropImage(saveData);
      }

      this.saving = true;
      let error: boolean = false;
      await CropVarietyStore.saveCropVariable({
        obj: temp,
        index: rowIndex
      }).catch(reason => {
        error = true;
      });
      this.saving = false;
      if (error) {
        this.$notify.error("Saving Failed");
      } else {
        CropStore.getAllCropName();
        this.$notify.success("Saved Successfully");
        this.handleRemove();
        this.dialogVisible = false;
      }
    } else {
      this.$notify.error("Invalid form data");
    }
  }

  async setCropVariableHomeCarousel(
    id: number,
    carousel: boolean,
    carousel_order: number | null,
    index: number
  ) {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (carousel == false) {
        carousel_order = null;
      }
      await CropVarietyStore.saveCropCarousel({
        id: id,
        carousel: carousel,
        carousel_order: carousel_order,
        index: index
      });
      // this.$notify.success("Saved Successfully");
    } else {
      // this.$notify.error("Invalid form data");
    }
  }

  async cropVariableSetting(metavarid: number, croptypeid: number) {
    this.dialogCropVariableSettingVisible = true;
    this.settingLoading = true;
    await CropVarietyStore.getCropVariableSetting({
      metavarid: metavarid,
      croptypeid: croptypeid
    });
    this.settingLoading = false;
  }

  async saveSetting() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.setting.querytype == "range" && this.setting.minValue && this.setting.maxValue) {
        this.setting.listofsearchvalues = [];
        this.setting.listofsearchvalues.push(String(this.setting.minValue));
        this.setting.listofsearchvalues.push(String(this.setting.maxValue));
      }
      this.savingSetting = true;
      let error: boolean = false;
      await CropVarietyStore.saveCropVariableSetting(this.setting).catch(reason => {
        error = true;
      });
      if (error) {
        this.$notify.error("Saving Failed");
      } else {
        this.$notify.success("Saved Successfully");
        this.dialogCropVariableSettingVisible = false;
      }
      this.savingSetting = false;
    } else {
      this.$notify.error("Invalid form data");
    }
  }

  addSearchValue() {
    if (this.setting.listofsearchvalues == null || this.setting.listofsearchvalues == undefined) {
      this.setting.listofsearchvalues = [];
    }
    this.setting.listofsearchvalues.push("");
  }

  removeSearchValue(index: number) {
    this.setting.listofsearchvalues.splice(index, 1);
  }

  private async created() {
    var urlpath = window.location.pathname;
    var urlsplit = urlpath.split("/");
    this.language = urlsplit[1];
    // if (this.$route.query.page) {
    //   this.pageNum = Number(this.$route.query.page);
    // }
    this.loading = true;
    await CropStore.getAllCropName();
    this.loading = false;
    // CropVarietyStore.getAllCropVarietyForAdmin("en");
    CropVarietyStore.getCropCategory();
  }
}
